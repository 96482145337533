import React from "react";

import extraMoneyPrizing from "../../../images/extra-money-prizing.png";

const Win = () => {
  return (
    <>
      <h2 className="text-uppercase mb-3 mb-md-4">
        <span className="text-primary-light">Chew. Post. </span>
        <span className="position-relative d-inline-flex justify-content-center">
          <span className="position-absolute"> You Could</span>Win
        </span>
        .
      </h2>
      <p className="text-xl mb-1 px-5">
        We're giving away <span className="text-primary-dark">$500</span> to
        three (3)
        <span className="d-lg-block"> lucky winners every day!</span>
      </p>
      <p className="text-primary-dark mb-0">Ends 4/30/22</p>
      <figure className="mb-0 px-5">
        <img
          src={extraMoneyPrizing}
          alt="Extra Money Prizing"
          className="img-fluid"
        />
      </figure>
    </>
  );
};

export default Win;
