import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Element, scroller } from "react-scroll";

import Form from "./upload-receipt/form";
import Popup from "./popup";

const UploadReceipt = () => {
  const [activeForm, setActiveForm] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const goToFormHandler = () => {
    scroller.scrollTo("formElement", {
      duration: 500,
      //delay: 100,
      smooth: true,
      //containerId: "ContainerElementID",
      //offset: 50, // Scrolls to element + 50 pixels down the page
    });
  };
  return (
    <>
      {showPopup && <Popup setShowPopup={setShowPopup} />}

      <section className="pb-5">
        <div className="container">
          <div className="text-center">
            {activeForm ? (
              <div className="accordion" id="accordionForm">
                <button
                  type="button"
                  className="btn btn-primary rounded-pill mx-2"
                  id="upload-btn"
                  data-bs-toggle="collapse"
                  data-bs-target="#form"
                  aria-expanded="false"
                  aria-controls="form"
                  onClick={goToFormHandler}
                >
                  Upload Receipt
                </button>
                <Element name="formElement"></Element>
                <div
                  id="form"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionForm"
                >
                  <div className="py-4 py-md-5">
                    {/* <h2 className="text-white text-uppercase mb-5">
                    Upload Receipt
                  </h2> */}
                    <figure className="mb-4 mb-md-5">
                      <StaticImage
                        src="../../images/chew-it-before-you-do-it.png"
                        alt="Chew it before you do it logo"
                        quality={100}
                      />
                    </figure>
                    <Form
                      setActiveForm={setActiveForm}
                      setShowPopup={setShowPopup}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h3 className="text-white">
                  Thank You for Submitting, if you are lucky winner our team
                  will get in touch.
                </h3>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadReceipt;
