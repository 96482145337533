import React from "react"

import iconsSelfieIcon from "../../../images/icons_selfie-icon.png"
import iconsReceiptIcon from "../../../images/icons_receipt-icon.png"

const Post = () => {
  return (
    <>
      <h2 className="text-uppercase mb-0 mb-md-4">
        <span className="text-primary-light">Chew. </span>
        Post.{" "}
        <span className="text-primary-light">
          <span className="position-relative d-inline-flex justify-content-center">
            <span className="position-absolute"> You Could</span>Win
          </span>
          .
        </span>
      </h2>
      <div className="d-md-flex justify-content-center align-items-center slide-post">
        <div className="post">
          <figure className="mb-0">
            <img
              src={iconsSelfieIcon}
              alt="icon Selfie"
              className="img-fluid"
            />
          </figure>
          <p className="text-lg mb-0">
            <span className="text-primary-dark">POST </span> to your public
            Twitter, TikTok or Instagram with #ChewItBeforeYouDoIt #Sweeps
          </p>
        </div>

        <h2 className="text-primary-dark text-uppercase">Or</h2>
        <div className="upload">
          <figure className="mb-0">
            <img
              src={iconsReceiptIcon}
              alt="Icon Reception"
              className="img-fluid"
            />
          </figure>
          <p className="text-lg mb-0">
            <span className="text-primary-dark">UPLOAD </span> your EXTRA® or
            ORBIT® receipt to be entered for a chance to win.
          </p>
        </div>
      </div>
    </>
  )
}

export default Post
