import React from "react"

const Intro = ({ goToCarousel, goToSlideTwo }) => {
  return (
    <>
      <section className="text-center text-white py-4 py-md-5 bg-gradient-dark-light">
        <div className="container pt-3">
          <h2 className="text-uppercase mb-3 mb-md-4 main-title">
            Chew. Post.
            <span className="position-relative d-inline-flex justify-content-center">
              <span className="position-absolute">You Could</span>Win
            </span>
            .
          </h2>
          <p className="text-xl mb-5">
            Every time you buy EXTRA® or ORBIT® gum this spring, you can enter
            for a chance to win $500!
          </p>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-primary rounded-pill mx-2"
              onClick={goToCarousel}
            >
              See How
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-pill mx-2"
              onClick={goToSlideTwo}
            >
              Upload Receipt
            </button>
          </div>
        </div>
      </section>
    </>
  )
}

export default Intro
