import React from "react"
import extraOrbitPackshots from "../../../images/extra-orbit-packshots.png"

const Chew = () => {
  return (
    <>
      <h2 className="text-uppercase mb-3 mb-md-4">
        Chew.{" "}
        <span className="text-primary-light">
          Post.{" "}
          <span className="position-relative d-inline-flex justify-content-center">
            <span className="position-absolute"> You Could</span>Win
          </span>
          .
        </span>
      </h2>
      <p className="text-xl mb-5">
        Buy packs of
        <span className="text-primary-dark"> EXTRA or ORBIT gum</span> and chew
        <span className="d-lg-block"> it when you need to take a pause.</span>
      </p>

      <figure className="mb-0">
        <img
          src={extraOrbitPackshots}
          alt="Extra Orbit Packshots"
          className="img-fluid"
        />
      </figure>
    </>
  )
}

export default Chew
