import React from "react"

import scrollWheelAnimation from "../../images/scroll-wheel-animation.gif"

const ScrollWheelAnimation = () => {
  return (
    <>
      <section className="position-relative scroll-wheel-animation">
        <div className="container">
          <figure className="mb-0">
            {/* <StaticImage
            src="../images/scroll-wheel-animation.gif"
            alt="Scroll Wheel Animation"
          /> */}
            <img
              src={scrollWheelAnimation}
              alt="Scroll Wheel Animation"
              className="img-fluid"
            />
          </figure>
        </div>
        <div className="helper-gradient"></div>
      </section>
    </>
  )
}

export default ScrollWheelAnimation
